import * as React from 'react'
import * as css from './MailchimpStatus.module.css'
import { navigate } from 'gatsby'

const MailchimpStatus = ({ status, message, redirectUrl }) => {
  if (redirectUrl && status === 'success') {
    navigate(redirectUrl)
  }

  return <>
    {status === 'sending' && (
      <div className={css.msg}>Sending...</div>
    )}

    {status === 'error' && (
      <div className={css.msg} dangerouslySetInnerHTML={{ __html: message }} />
    )}

    {status === 'success' && (
      <div className={css.msg} dangerouslySetInnerHTML={{ __html: message }} />
    )}
  </>
}

export default MailchimpStatus
