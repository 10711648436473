import * as React from 'react'
import clsx from 'clsx'
import nl2br from 'utils/nl2br'

const Locations = ({ title, items }) => {
  const groups = [...new Set(
    items.map(({group}) => group)
  )]

  return (
    <section className="ctnr padding">
      <h2 className="h0">{title}</h2>

      <div className="grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-8 mt-8">
        {groups.map(group =>
          items.filter(item => item.group === group).map((item, key) => (
            <div className="grid gap-1" key={key}>
              <div className={clsx(key >= 1 && 'invisible', 'mb-2 technical-lg text-ink-light')}>
                {group}
              </div>

              <div className="font-bold">{item.title}</div>

              {item.tel && (
                <div><a href={`tel:${item.tel}`}>{item.tel}</a></div>
              )}

              <div>
                <address>{nl2br(item.address)}</address>
              </div>
            </div>
          ))
        )}
      </div>
    </section>
  )
}

export default Locations
