import * as React from 'react'
import * as css from './ContactForms.module.css'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import GeneralForm from './GeneralForm'
import HvacForm from './HvacForm'
import clsx from 'clsx'

const tabs = [
  'General Inquiry',
  'Commercial HVAC Services',
]

const ContactForms = () => {
  const [$activeTab, set$activeTab] = React.useState(0)

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    if (params.get('form') === 'hvac') {
      set$activeTab(1)
    }
  }, [])

  return (
    <div>
      <div className="overflow-x-auto no-scrollbar">
        <div className="flex">
          {tabs.map((tab, key) => (
            <button
              className={clsx(css.button, key === $activeTab && css.active)}
              onClick={() => set$activeTab(key)}
              key={key}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>

      <div className={css.contents}>
        <div className="anim-fadein" hidden={$activeTab !== 0}>
          <MailchimpSubscribe
            url="https://fastechus.us2.list-manage.com/subscribe/post?u=1b5912a7a12178df95b8ef6cc&id=311c021628"
            render={props => <GeneralForm {...props} />}
          />
        </div>

        <div className="anim-fadein" hidden={$activeTab !== 1}>
          <MailchimpSubscribe
            url="https://fastechus.us2.list-manage.com/subscribe/post?u=1b5912a7a12178df95b8ef6cc&id=f0c1cabfde"
            render={props => <HvacForm {...props} />}
          />
        </div>
      </div>
    </div>
  )
}

export default ContactForms
