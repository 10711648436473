import * as React from 'react'
import * as css from './Hero.module.css'
import SanityBlock from 'components/common/SanityBlock'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import ContactForms from './ContactForms'

const Hero = React.forwardRef(({ title, _rawDescription, image }, ref) => {
  const { contact, hvacTel } = useStaticQuery(query).sanitySite

  return (
    <section className="relative bg-suede-black text-white">
      <div className={css.trigger} ref={ref}/>

      {image && <GatsbyImage
        className="pointer-events-none !absolute inset-0 opacity-20 grayscale"
        image={getImage(image.asset)}
        alt={title}
      />}

      <div className={`${css.grid} grid md:grid-cols-2 gap-y-16 gap-x-8 items-center ctnr relative`}>
        <div className="px-8 max-w-[450px] richtext">
          <h1 className={css.title}>{title}</h1>

          <SanityBlock body={_rawDescription} />

          <dl className={css.contact}>
            <dt>General Inquiries</dt>
            <dd><a className="link-strong" href={`tel:${contact.tel}`}>{contact.tel}</a></dd>

            <dt>Commercial HVAC</dt>
            <dd><a className="link-strong" href={`tel:${hvacTel}`}>{hvacTel}</a></dd>

            <dt>Email</dt>
            <dd><a className="link-strong" href={`mailto:${contact.email}`}>{contact.email}</a></dd>
          </dl>
        </div>

        <div className="min-w-0 <md:px-8">
          <ContactForms/>
        </div>
      </div>
    </section>
  )
})

export default Hero

const query = graphql`query ContactHero {
  sanitySite {
    contact {
      tel
      email
    }
    hvacTel
  }
}
`
