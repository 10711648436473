import * as React from 'react'
import * as css from './form.module.css'
import { MdKeyboardArrowDown } from 'react-icons/md'
import clsx from 'clsx'

const Input = React.forwardRef(({ label, required, optional, className, ...props }, ref) => (
  <label className={className}>
    <div className={clsx(css.label, required && css.required, optional && css.optional)}>
      {label}
    </div>
    <input ref={ref} className={css.input} placeholder={label} {...props} />
  </label>
))

const Textarea = React.forwardRef(({ label, required, optional, className, ...props}, ref) => (
  <label className={className}>
    <div className={clsx(css.label, required && css.required, optional && css.optional)}>
      {label}
    </div>
    <textarea ref={ref} className={css.input} placeholder={label} {...props} />
  </label>
))

const Select = React.forwardRef(({ label, required, optional, className, ...props }, ref) => (
  <label className={className}>
    <div className={clsx(css.label, required && css.required, optional && css.optional)}>
      {label}
    </div>

    <div className={css.select_wrapper}>
      <select ref={ref} className={css.select} {...props} />
      <MdKeyboardArrowDown className={css.down} />
    </div>
  </label>
))

export {
  Input,
  Textarea,
  Select,
}
