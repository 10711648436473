import * as React from 'react'
import DefaultLayout from 'layouts/Default'
import { useInView } from 'react-intersection-observer'
import { graphql, useStaticQuery } from 'gatsby'
import Hero from 'components/pg-contact/Hero'
import Locations from 'components/pg-contact/Locations'

const ContactPg = () => {
  const { sanityContactPg: pg } = useStaticQuery(query)

  const { ref, inView } = useInView({
    rootMargin: '-64px 0px 0px 0px'
  })

  return (
    <DefaultLayout {...pg.seo} transparentHeader={{ ref, inView }}>
      <Hero {...pg.hero} ref={ref} />
      <Locations {...pg.locations} />
    </DefaultLayout>
  )
}

export default ContactPg

const query = graphql`query ContactPg {
  sanityContactPg {
    hero {
      title
      _rawDescription
      image {
        image { asset { gatsbyImageData(placeholder: NONE) } }
        alt caption
      }
    }
    locations {
      title
      items {
        title
        tel
        address
        group
      }
    }
    seo { title description }
  }
}`
