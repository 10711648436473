import * as React from 'react'
import * as css from './GeneralForm.module.css'
import { Input, Select, Textarea } from 'components/form/Inputs'
import MailchimpStatus from 'components/common/MailchimpStatus'

const HvacForm = ({ subscribe, status, message }) => {
  const fname = React.useRef(null)
  const lname = React.useRef(null)
  const email = React.useRef(null)
  const tel = React.useRef(null)
  const company = React.useRef(null)
  const zip = React.useRef(null)
  const services = React.useRef(null)
  const comments = React.useRef(null)

  const submit = () =>
    fname.current.value &&
    lname.current.value &&
    email.current.validity.valid &&
    subscribe({
      MERGE1: fname.current.value,
      MERGE2: lname.current.value,
      MERGE0: email.current.value,
      MERGE4: tel.current.value,
      MERGE3: company.current.value,
      MERGE5: zip.current.value,
      MERGE6: services.current.value,
      MERGE7: comments.current.value,
    })

  return <>
    <div>
      <div className={css.inputs}>
        <Input ref={fname} label="First Name" />
        <Input ref={lname} label="Last Name" />
        <Input ref={email} label="Email" type="email" />
        <Input ref={tel} label="Phone Number" />
        <Input ref={company} label="Company" optional />
        <Input ref={zip} label="Zip Code" optional />

        <Select ref={services} className={css.stretch} label="Services Needed" optional>
          <option value="" default>{''}</option>
          <option value="HVAC">HVAC</option>
          <option value="Refrigeration">Refrigeration</option>
          <option value="Unit Replacement">Unit Replacement</option>
          <option value="Construction">Construction</option>
        </Select>

        <Textarea ref={comments} className={css.stretch} label="Comments" optional></Textarea>
      </div>

      <div className={css.bottom}>
        <button className="action lg" onClick={submit}>
          Get started
          <span className="arrow">➞</span>
        </button>
      </div>
    </div>

    <MailchimpStatus
      status={status}
      message={message}
      redirectUrl="/contact/hvac-thank-you"
    />
  </>
}

export default HvacForm
